import FontFaceObserver from 'fontfaceobserver';

export default () => {
	const $html = document.getElementsByTagName('html')[0];
	const heading = new FontFaceObserver('Neue Helvetica');
	const body = new FontFaceObserver('inter');

	Promise.all([heading.load(), body.load()])
		.then(() => $html.classList.add('fonts-loaded'))
		.catch((a, b, c) => $html.classList.add('fonts-failed'));
};
