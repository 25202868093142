import local from 'local-links';

export default (container = document) => {
	[...container.querySelectorAll('a')]
		.filter(
			a =>
				local.isLocal('click', a, true) === null &&
				a.href !== window.location.href
		)
		.forEach(a => {
			if (!a.hasAttribute('data-self')) {
				a.setAttribute('target', '_blank');
				a.setAttribute('rel', 'noopener');
			}
		});
};
